import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import './blog-post.css'

const useConvertKit = ({src, ref, uid}) => {
  useEffect(() => {
    if (!document.querySelector('.formkit-form')) {
      const script = document.createElement('script')
      script.src = src
      script.async=true
      script.setAttribute('data-uid', uid)
      ref.current.appendChild(script)
    }
  }, [src, ref, uid])
}

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const { previous, next } = pageContext

  const html = useMemo(() => {
    return post.html.replace(/<p><video.*<\/video><\/p>/g, ($1) => `<p class="post__gif"><button><span></span></button>${$1.slice(3, $1.length)}`)
  }, [post.html])
  
  const ctaHolder = useRef(null)
  useConvertKit({
    ref: ctaHolder,
    src: 'https://skilled-knitter-2150.ck.page/b981c92199/index.js',
    uid: 'b981c92199'
  })

  /**
   * Gifs to videos
   */
  useLayoutEffect(() => {
    let videos = document.querySelectorAll('.post video')
    const cleanUps = []
    Array.from(videos).forEach((video) => {
      
      /**
       * Safari doesn't always autoplay videos
       * with autoplay attribute
       */
      if (video.autoplay) {
        video.play()
      }

      /**
       * Safari doesn't show the first frame of
       * non-autoplaying videos
       * https://stackoverflow.com/questions/56428378/no-way-to-preload-first-video-frame-on-ios-safari
       */
       if (!video.autoplay) {
        video.src = video.src + '#t=0.1'
      }

      /**
       * Set the initial state to control button
       */
      if (video.paused) {
        video.parentNode.classList.add('post__gif--paused')
      }

      const wrapper = video.parentNode
      const button = wrapper.querySelector('button')

      const playHandler = () => {
        wrapper.classList.remove('post__gif--paused')
      }
      
      const pauseHandler = () => {
        wrapper.classList.add('post__gif--paused')
      }

      const butonHandler = () => {
        if (wrapper.classList.contains('post__gif--paused')) {
          wrapper.classList.remove('post__gif--paused')
          video.play()
        } else {
          wrapper.classList.add('post__gif--paused')
          video.pause()
        }
      }
      
      video.addEventListener('play', playHandler)

      video.addEventListener('pause', pauseHandler)

      button.addEventListener('click', butonHandler)

      cleanUps.push(() => {
        video.removeEventListener('play', playHandler)
        video.removeEventListener('pause', pauseHandler)
        video.removeEventListener('click', butonHandler)
      })

    })

    return () => {
      cleanUps.forEach(cleanUp => cleanUp())
    }

  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={`${siteUrl}/og/${post.frontmatter.image}`}
      />
      <article className="post">
        <header>
          <h1
            style={{
              fontFamily: 'Montserrat, Arial Black, sans-serif',
              fontWeight: 900,
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
              marginTop: rhythm(.2),
              color: 'rgba(0, 0, 0, .6)'
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: html }} />
        <footer>
          <div className="cta-holder" ref={ctaHolder} />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            margin: '0',
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title,
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image
      }
    }
  }
`
